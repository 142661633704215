let currentIndex = -1;

function setSuggestion(event: KeyboardEvent) {
  const container = document.getElementById("suggestions")!;
  const suggestions = container.querySelectorAll("ul li");

  const form = container.previousElementSibling;
  form?.classList?.add("rounded-b-none");

  let direction = 0;
  if (event.key === "ArrowUp") {
    direction = -1;
  } else if (event.key === "ArrowDown") {
    direction = 1;
  } else if (event.key === "Enter" && currentIndex > -1) {
    const suggestion = suggestions[currentIndex];
    const query = (suggestion?.querySelector("a")?.dataset?.query ?? "").trim();
    const currentTarget = event.currentTarget as HTMLInputElement;
    currentTarget.value = query;

    currentIndex = -1;
    form?.classList?.remove("rounded-b-none");

    return;
  } else if (event.key === "Escape") {
    currentIndex = -1;
    form?.classList?.remove("rounded-b-none");

    const currentTarget = event.currentTarget as HTMLInputElement;
    currentTarget.value = "";
    currentTarget!.dispatchEvent(new Event('input'));
    container.replaceChildren();

    return;
  }

  if (direction === 0) {
    currentIndex = -1;

    return;
  }

  event.preventDefault();

  for (const suggestion of suggestions) {
    suggestion.classList.remove("bg-gray-100");
  }

  const numElements = suggestions.length;
  currentIndex += direction;
  if (currentIndex < numElements && currentIndex > -1) {
    const nextSuggestion = suggestions[currentIndex]
    nextSuggestion.classList.add("bg-gray-100");
  } else {
    currentIndex = -1;
  }
}

window.setSuggestion = setSuggestion;
